import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
  ItemInterface,
  StockTransferStatus,
  StockTransferTaskFormInterface,
  StockTransferTaskInterface,
} from '@meeva/service-client-core/interfaces/StockTransferInterface';
import { DB } from '@meeva/service-client-core/modules/app/client/dataProvider/storageClient';
import AppConfig from '@meeva/service-client-core/modules/app/config';
import { setSnack, setTitle, showGlobalProgressModal } from '@meeva/service-client-core/redux/interface/actions';
import { triggerBackgroundSync } from '@meeva/service-client-core/redux/interface/operations';
import { BusinessUnitInterface } from '@meeva/service-client-core/src/interfaces/BusinessUnitInterface';
import { playErrorSound, playSuccessSound, playWarningSound } from '@meeva/service-client-core/utils/audioHelper';
import createDebugLogger from '@meeva/service-client-core/utils/debug';
import { findItemByCode } from '@meeva/service-client-core/utils/items';
import {
  clearTaskList,
  createOrUpdateTask,
  createSealedContainer,
  getTasks,
  modifyTask,
  removeTask,
} from '@meeva/service-client-core/utils/stockTransferTaskHelper';
import { useLiveQuery } from 'dexie-react-hooks';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import StockTransferBusinessUnitSelect from './StockTransferBusinessUnitSelect';
import StockTransferItemQuantityDialog from './StockTransferItemQuantityDialog';
import StockTransferItemRow, { TaskRowInterface } from './StockTransferItemRow';
import ItemRowContextMenu from './StockTransferItemRowContextMenu';

const ERROR_MESSAGE_MISSING_BUSINESS_UNIT = 'Es muss vorher eine Filiale ausgewählt werden';
const ERROR_MESSAGE_INVALID_BUSINESS_UNIT = 'Die angegebene Filial-Nummer ist ungültig';
const ERROR_MESSAGE_NOT_FROUND_BUSINESS_UNIT = 'Die gesuchte Filiale konnte nicht gefunden werden';
const ERROR_MESSAGE_MISSING_CONTAINER = 'Es muss vorher ein Container angegeben werden';
const ERROR_MESSAGE_NOT_FOUND_ITEM = 'Der gesuchte Artikel konnte nicht gefunden werden';

interface ContextMenuInterface {
  anchorEl: EventTarget | null;
  selectedTask: StockTransferTaskFormInterface | TaskRowInterface | null;
  taskType: 'ItemTask' | 'ContainerSealTask' | null;
}

interface FormErrorStateInterface {
  containerMsg: string;
  destinationBusinessUnitMsg: string;
}

interface LatestScannedTaskInterface {
  id?: string;
  itemId: string;
  destinationBusinessUnitId: string;
  containerCode: string;
  status: StockTransferStatus;
}

// TODO: Generic hook for all Paper-Components?
const useFormStyles = makeStyles({
  paper: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  paperSearch: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
});

const businessUnitCache: any = {};

const getDestinationBusinessUnit = async (destinationBusinessUnitId: string) => {
  if (!businessUnitCache[destinationBusinessUnitId]) {
    businessUnitCache[destinationBusinessUnitId] = await DB.businessUnits
      .where('id')
      .equals(destinationBusinessUnitId)
      .first();
  }

  return businessUnitCache[destinationBusinessUnitId];
};

const logDebug = createDebugLogger('itemInventory/stockTransfer/free');

const FreeStockTransferForm = () => {
  const [scannedBarcode, setScannedBarcode] = useState<string>('');
  const [containerCode, setContainerCode] = useState<string>('');
  const [scannedTaskList, setScannedTaskList] = useState<TaskRowInterface[]>([]);
  const [containerSealings, setContainerSealings] = useState<TaskRowInterface[]>([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [latestScannedTask, setLatestScannedTask] = useState<LatestScannedTaskInterface>();
  const [destinationBusinessUnit, setDestinationBusinessUnit] = useState<BusinessUnitInterface | null>(null);
  const [formErrorState, setFormError] = useState<FormErrorStateInterface>({
    containerMsg: '',
    destinationBusinessUnitMsg: '',
  });
  const [quantityDialogTask, setQuantityDialogTask] = useState<StockTransferTaskFormInterface | null>(null);
  const [contextMenu, setContextMenu] = useState<ContextMenuInterface>({
    anchorEl: null,
    selectedTask: null,
    taskType: null,
  });

  const classes = useFormStyles();
  const dispatch = useDispatch();
  const scannerInputRef = useRef<HTMLInputElement>();

  const queuedTasks: StockTransferTaskInterface[] = useLiveQuery(() => getTasks(null, null, null, 'free')) || [];

  useEffect(() => {
    (async () => {
      const scannedTaskMap = new Map<string, TaskRowInterface>();
      const containerSealingsArray: TaskRowInterface[] = [];

      for (const task of queuedTasks) {
        if (
          [StockTransferStatus.CONTAINER_SEALING, StockTransferStatus.CONTAINER_SEALING_ERROR].includes(task.status)
        ) {
          containerSealingsArray.push({
            type: 'sealing',
            destinationBusinessUnit: await getDestinationBusinessUnit(task.destinationBusinessUnitId),
            containerCode: task.containerCode,
            status: task.status,
            scannedQuantity: 0,
            error: task.error,
          });
        }

        for (const lineItem of task.lineItems) {
          const taskKey =
            [
              StockTransferStatus.NEW,
              StockTransferStatus.READY,
              StockTransferStatus.SENT,
              StockTransferStatus.SENDING,
            ].includes(task.status) || !task.id
              ? `${task.destinationBusinessUnitId}_${task.containerCode}_${task.status}_${lineItem.item.id}`
              : task.id.toString();
          const existingTask = scannedTaskMap.get(taskKey);
          const scannedQuantity = (existingTask?.scannedQuantity || 0) + lineItem.unitCount;
          scannedTaskMap.set(taskKey, {
            id: StockTransferStatus.SENT !== task.status ? task.id : undefined,
            type: 'item',
            item: lineItem.item,
            destinationBusinessUnit: await getDestinationBusinessUnit(task.destinationBusinessUnitId),
            containerCode: task.containerCode,
            scannedQuantity,
            status: task.status,
            error: task.error,
          } as TaskRowInterface);

          const sumTaskKey = `${task.destinationBusinessUnitId}_${task.containerCode}_${task.status}`;
          const existingSumTask = scannedTaskMap.get(sumTaskKey);

          scannedTaskMap.set(sumTaskKey, {
            type: 'sum',
            destinationBusinessUnit: await getDestinationBusinessUnit(task.destinationBusinessUnitId),
            containerCode: task.containerCode,
            scannedQuantity: (existingSumTask?.scannedQuantity || 0) + scannedQuantity,
            status: task.status,
          } as TaskRowInterface);
        }
      }

      setScannedTaskList(
        [...scannedTaskMap.values()]
          .map((value) => value)
          .sort((taskA, taskB) => {
            let itemNumberA = 'sum';
            if (taskA.item) {
              itemNumberA = taskA.item.customItemNo || taskA.item.itemNo;
            }
            let itemNumberB = 'sum';
            if (taskB.item) {
              itemNumberB = taskB.item.customItemNo || taskB.item.itemNo;
            }

            return taskA.containerCode.localeCompare(taskB.containerCode) || itemNumberA.localeCompare(itemNumberB);
          })
      );

      setContainerSealings(containerSealingsArray);
    })();
  }, [queuedTasks]);

  useEffect(() => {
    if (scannerInputRef.current) {
      scannerInputRef.current.focus();
    }
  }, [scannedBarcode, destinationBusinessUnit]);

  useEffect(() => {
    notifyServiceWorker();
  }, [scannedTaskList]);

  useEffect(() => {
    dispatch(setTitle('Freie Kistenbestückung'));
  }, []);

  const notifyServiceWorker = useCallback(
    debounce(
      async () => {
        await dispatch(triggerBackgroundSync('stockTransferTasks', true));
      },
      10000,
      { maxWait: 30000 }
    ),
    []
  );

  const changeTaskQuantity = async (
    id: string | undefined,
    item: ItemInterface,
    destinationBusinessUnit: BusinessUnitInterface,
    container: string,
    quantity: number
  ) => {
    dispatch(showGlobalProgressModal(true));
    if (!id) {
      id = await createOrUpdateTask(item, container, destinationBusinessUnit.id, quantity, 'free');
    } else {
      if (!(await modifyTask(id, item, quantity))) {
        // Kleiner Hack: Während Daten Übertragen werden, kann die Menge nicht geändert werden.
        await new Promise((r) => setTimeout(r, 750));
        if (!(await modifyTask(id, item, quantity))) {
          displayErrorNotification('Menge konnte nicht geändert werden. Bitte erneut probieren.');
          dispatch(showGlobalProgressModal(false));
          return false;
        }
      }
    }
    setLatestScannedTask({
      id,
      itemId: item.id,
      destinationBusinessUnitId: destinationBusinessUnit.id,
      containerCode: container,
      status: StockTransferStatus.READY,
    });

    dispatch(showGlobalProgressModal(false));
    return true;
  };

  const displayErrorNotification = (message: string) => {
    dispatch(
      setSnack({
        text: message,
        severity: 'warning',
        autoHideDuration: 5000,
      })
    );
  };

  const handleBusinessUnitChange = async (
    businessUnit: BusinessUnitInterface | string | null,
    isCalledByScanEvent = false
  ) => {
    setFormError((prevState) => ({ ...prevState, destinationBusinessUnitMsg: '' }));

    if (!businessUnit) {
      isCalledByScanEvent && playErrorSound('businessUnit');
      return setDestinationBusinessUnit(null);
    }

    let _businessUnit: BusinessUnitInterface | string | undefined = businessUnit;

    if (typeof _businessUnit === 'string') {
      if (!Number(businessUnit)) {
        isCalledByScanEvent && playErrorSound('businessUnit');
        return displayErrorNotification(ERROR_MESSAGE_INVALID_BUSINESS_UNIT);
      }

      _businessUnit = await DB.businessUnits.where('number').equals(Number(_businessUnit)).first();

      if (!_businessUnit) {
        isCalledByScanEvent && playErrorSound('businessUnit');
        return displayErrorNotification(ERROR_MESSAGE_NOT_FROUND_BUSINESS_UNIT);
      }
    }

    isCalledByScanEvent && playSuccessSound('businessUnit');
    setDestinationBusinessUnit(_businessUnit);
    setContainerCode('');
  };

  const handleItemScan = async (scannedBarcode: string, quantity = 1) => {
    dispatch(showGlobalProgressModal(true));

    try {
      let _item: ItemInterface | string | undefined = scannedBarcode;

      if (!containerCode) {
        playWarningSound('item');
        setFormError((prevState) => ({
          ...prevState,
          containerMsg: ERROR_MESSAGE_MISSING_CONTAINER,
        }));
        dispatch(showGlobalProgressModal(false));
        return displayErrorNotification(ERROR_MESSAGE_MISSING_CONTAINER);
      }

      if (!destinationBusinessUnit) {
        playWarningSound('item');
        setFormError((prevState) => ({
          ...prevState,
          destinationBusinessUnitMsg: ERROR_MESSAGE_MISSING_BUSINESS_UNIT,
        }));
        dispatch(showGlobalProgressModal(false));
        return displayErrorNotification(ERROR_MESSAGE_MISSING_BUSINESS_UNIT);
      }

      if (typeof _item === 'string') {
        _item = await findItemByCode(_item);
      }

      if (!_item) {
        playErrorSound('item');
        dispatch(showGlobalProgressModal(false));
        return displayErrorNotification(ERROR_MESSAGE_NOT_FOUND_ITEM);
      }

      logDebug(
        'handleItemScan',
        {
          scannedBarcode,
          item: _item.customItemNo || _item.itemNo,
          businessUnit: destinationBusinessUnit.number,
          quantity,
        },
        {
          item: _item.itemNo,
          containerCode,
        }
      );
      await changeTaskQuantity(undefined, _item, destinationBusinessUnit, containerCode, quantity);
      playSuccessSound('item');
    } catch (exception) {
      playErrorSound('item');
      console.error(exception);
      displayErrorNotification('Ein unbekannter Fehler ist aufgetreten');
    } finally {
      dispatch(showGlobalProgressModal(false));
    }
  };

  const handleScan = useCallback(() => {
    if (scannedBarcode.match(AppConfig.getConfig()?.itemInventory?.containerBarcode || /^C/)) {
      if (!destinationBusinessUnit) {
        playErrorSound('container');
        setFormError((prevState) => ({
          ...prevState,
          containerMsg: '',
          destinationBusinessUnitMsg: ERROR_MESSAGE_MISSING_BUSINESS_UNIT,
        }));

        displayErrorNotification(ERROR_MESSAGE_MISSING_BUSINESS_UNIT);
      } else {
        setFormError((prevState) => ({ ...prevState, containerMsg: '' }));
        setContainerCode(scannedBarcode);
        playSuccessSound('container');
      }
    } else if (scannedBarcode.match(/^F\d+$/)) {
      setContainerCode('');
      handleBusinessUnitChange(scannedBarcode.substring(1), true);
    } else {
      handleItemScan(scannedBarcode);
    }

    setScannedBarcode('');
  }, [scannedBarcode]);

  const handleContainerSeal = useCallback(async () => {
    if (!containerCode) {
      return dispatch(
        setSnack({
          text: 'Es muss ein Container angegeben sein',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );
    }

    if (!destinationBusinessUnit) {
      return dispatch(
        setSnack({
          text: 'Es muss eine Filiale ausgewählt sein',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );
    }

    dispatch(showGlobalProgressModal(true));

    try {
      const tasksToProcess = await getTasks(containerCode, destinationBusinessUnit.id, [
        StockTransferStatus.READY,
        StockTransferStatus.SENDING,
      ]);

      if (tasksToProcess.length) {
        setIsConfirmationDialogOpen(false);
        dispatch(showGlobalProgressModal(false));
        return dispatch(
          setSnack({
            text: 'Es werden noch Artikel zu diesem Container im Hintergrund bearbeitet. Bitte versuchen Sie es gleich erneut.',
            severity: 'warning',
            autoHideDuration: 5000,
          })
        );
      }

      await createSealedContainer(containerCode, destinationBusinessUnit.id);
      setIsConfirmationDialogOpen(false);
      setContainerCode('');
      await dispatch(triggerBackgroundSync('stockTransferSealContainerTasks', true));
      setDestinationBusinessUnit(null);
    } catch (error) {
      dispatch(
        setSnack({
          text: 'Es ist ein Fehler aufgetreten',
          severity: 'warning',
          autoHideDuration: 5000,
        })
      );

      console.error(error);
    }

    dispatch(showGlobalProgressModal(false));
  }, [containerCode, destinationBusinessUnit]);

  return (
    <Container>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StockTransferBusinessUnitSelect
              onChange={handleBusinessUnitChange}
              value={destinationBusinessUnit}
              errorMessage={formErrorState.destinationBusinessUnitMsg}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Barcode scannen oder eingeben"
              variant="outlined"
              inputRef={scannerInputRef}
              value={scannedBarcode}
              onChange={(event) => setScannedBarcode(event.target.value)}
              error={!!formErrorState.containerMsg}
              helperText={containerCode ? `Container: ${containerCode}` : formErrorState.containerMsg}
              onKeyDown={(event) => {
                if ('Enter' === event.key || '13' === event.code) {
                  event.preventDefault();
                  handleScan();
                }
              }}
              data-cy="stock-transfer-form-scan-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Suche starten" onClick={() => handleScan} edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={!containerCode && !destinationBusinessUnit}
              onClick={() => setIsConfirmationDialogOpen(!isConfirmationDialogOpen)}
              fullWidth
            >
              Container Abschließen
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableContainer data-cy="stock-transfer-form-item-table">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">Ziel</TableCell>
                    <TableCell component="th" align="right">
                      Menge
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {containerSealings.map((containerSealing, key) => (
                    <StockTransferItemRow
                      task={containerSealing}
                      key={'containerSealing' + key}
                      onRowClick={(event) => {
                        if (StockTransferStatus.CONTAINER_SEALING_ERROR === containerSealing.status) {
                          setContextMenu({
                            anchorEl: event.target,
                            selectedTask: containerSealing,
                            taskType: 'ContainerSealTask',
                          });
                        }
                      }}
                    />
                  ))}
                  {scannedTaskList.map((task, key) => {
                    const highlightRow = Boolean(
                      latestScannedTask &&
                        task.item &&
                        task.item.id === latestScannedTask.itemId &&
                        task.destinationBusinessUnit.id === latestScannedTask.destinationBusinessUnitId &&
                        task.containerCode === latestScannedTask.containerCode &&
                        task.status === latestScannedTask.status
                    );

                    return (
                      <StockTransferItemRow
                        task={task}
                        key={key}
                        highlightAddition={highlightRow}
                        addDivider={
                          scannedTaskList[key + 1] && scannedTaskList[key + 1].containerCode !== task.containerCode
                        }
                        onRowClick={(event) => {
                          setContextMenu({
                            anchorEl: event.target,
                            selectedTask: task as StockTransferTaskFormInterface,
                            taskType: 'ItemTask',
                          });
                        }}
                      />
                    );
                  })}
                  {!scannedTaskList.length && !containerSealings.length && (
                    <TableRow data-cy="stock-transfer-form-item-table-row">
                      <TableCell colSpan={2} align="center">
                        Die Liste ist leer
                      </TableCell>
                    </TableRow>
                  )}
                  <ItemRowContextMenu
                    open={!!contextMenu.selectedTask}
                    onClose={() => setContextMenu({ anchorEl: null, selectedTask: null, taskType: null })}
                    anchorEl={contextMenu.anchorEl}
                    {...('ItemTask' === contextMenu.taskType &&
                      contextMenu.selectedTask!.item && {
                        onReduction:
                          (contextMenu.selectedTask?.scannedQuantity || 0) > 0
                            ? async () => {
                                const typedSelectedTask = contextMenu.selectedTask as StockTransferTaskFormInterface;

                                logDebug(
                                  'changeTaskQuantity',
                                  {
                                    item: typedSelectedTask.item.customItemNo || typedSelectedTask.item.itemNo,
                                    businessUnit: typedSelectedTask.destinationBusinessUnit.number,
                                    quantity: -1,
                                  },
                                  {
                                    containerCode: typedSelectedTask.containerCode,
                                  }
                                );
                                await changeTaskQuantity(
                                  typedSelectedTask.id,
                                  typedSelectedTask.item,
                                  typedSelectedTask.destinationBusinessUnit,
                                  typedSelectedTask.containerCode,
                                  -1
                                );
                                setContextMenu({ anchorEl: null, selectedTask: null, taskType: null });
                              }
                            : undefined,
                        onQuantityEdit:
                          (contextMenu.selectedTask?.scannedQuantity || 0) > 0
                            ? () => {
                                setQuantityDialogTask(contextMenu.selectedTask as StockTransferTaskFormInterface);
                                setContextMenu({ anchorEl: null, selectedTask: null, taskType: null });
                              }
                            : undefined,
                        onRemoveError:
                          contextMenu!.selectedTask?.status === StockTransferStatus.ERROR
                            ? async () => {
                                const typedSelectedTask = contextMenu.selectedTask as StockTransferTaskFormInterface;

                                logDebug(
                                  'removeErroredTask',
                                  {
                                    item: typedSelectedTask.item.customItemNo || typedSelectedTask.item.itemNo,
                                    businessUnit: typedSelectedTask.destinationBusinessUnit.number,
                                    taskQuantity: typedSelectedTask.scannedQuantity,
                                  },
                                  {
                                    containerCode: typedSelectedTask.containerCode,
                                  }
                                );
                                await removeTask({ id: typedSelectedTask.id }, 'free', StockTransferStatus.ERROR);
                                setContextMenu({ anchorEl: null, selectedTask: null, taskType: null });
                              }
                            : undefined,
                      })}
                    {...('ContainerSealTask' === contextMenu.taskType && {
                      onRemoveError: async () => {
                        const { destinationBusinessUnit, containerCode } =
                          contextMenu.selectedTask as StockTransferTaskFormInterface;

                        await removeTask(
                          { destinationBusinessUnitId: destinationBusinessUnit.id, containerCode },
                          'free',
                          StockTransferStatus.CONTAINER_SEALING_ERROR
                        );
                        setContextMenu({ anchorEl: null, selectedTask: null, taskType: null });
                      },
                    })}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2} textAlign="right">
              <Button
                variant="contained"
                color="default"
                disabled={!queuedTasks.find((task) => task.status === StockTransferStatus.SENT)}
                onClick={() => clearTaskList('free')}
              >
                Liste leeren
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {quantityDialogTask && (
        <StockTransferItemQuantityDialog
          task={quantityDialogTask}
          onClose={() => setQuantityDialogTask(null)}
          onSave={async (newQuantity) => {
            logDebug(
              'changeTaskQuantity',
              {
                item: quantityDialogTask!.item.customItemNo || quantityDialogTask!.item.itemNo,
                businessUnit: quantityDialogTask!.destinationBusinessUnit.number,
                quantity: newQuantity - quantityDialogTask!.scannedQuantity,
              },
              {
                containerCode: quantityDialogTask!.containerCode,
              }
            );
            await changeTaskQuantity(
              quantityDialogTask!.id,
              quantityDialogTask!.item,
              quantityDialogTask!.destinationBusinessUnit,
              quantityDialogTask!.containerCode,
              newQuantity - quantityDialogTask!.scannedQuantity
            );
            setQuantityDialogTask(null);
          }}
        />
      )}
      <Dialog
        open={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        data-cy="free-stock-transfer-form-confirm-dialog"
      >
        <DialogTitle>Achtung</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Der Inhalt des Containers kann nach dem Verplomben nicht mehr verändert werden.
            <br />
            Wurde der Container bereits verplombt?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsConfirmationDialogOpen(false)} fullWidth>
            Abbrechen
          </Button>
          <Button variant="contained" color="primary" onClick={handleContainerSeal} fullWidth>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FreeStockTransferForm;
