import { POSIdentityInterface } from '@meeva/erp-types-core';
import { BusinessUnitInterface } from './BusinessUnitInterface';

enum StockTransferStatus {
  NEW,
  READY,
  SENT,
  ERROR,
  CONTAINER_SEALING,
  CONTAINER_SEALING_ERROR,
  SENDING,
}

type StockTransferTaskType = 'free' | 'request' | 'declineRequest';

interface ItemInterface {
  id: string;
  itemNo: string;
  description?: string;
  customItemNo?: string;
  posIdentities?: POSIdentityInterface[];
  scanCodes?: string[];
}

interface StockTransferTaskInterface {
  id?: string;
  createdAt: Date;
  updatedAt?: Date;
  type: StockTransferTaskType;
  destinationBusinessUnitId: string;
  containerCode: string;
  lineItems: StockTransferLineItemInterface[];
  status: StockTransferStatus;
  requestDocumentId?: string;
  error?: string;
}

interface StockTransferTaskFormInterface {
  id?: string;
  item: ItemInterface;
  destinationBusinessUnit: BusinessUnitInterface;
  containerCode: string;
  scannedQuantity: number;
  status: StockTransferStatus;
  error?: string;
}

interface StockTransferLineItemInterface {
  item: ItemInterface;
  unitCount: number;
}

export { StockTransferStatus };

export type {
  StockTransferTaskType,
  StockTransferTaskInterface,
  StockTransferTaskFormInterface,
  StockTransferLineItemInterface,
  ItemInterface,
};
